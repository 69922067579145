/*
 * IMPORT
 */
import React, { Suspense, useEffect, useState } from "react"; // NPM: React package.
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom"; // NPM: React Router Dom for routing.
import { connect } from "react-redux"; // NPM: React Redux for state management.
import { Flex, Spinner } from "@chakra-ui/react";
import { Toaster } from "react-hot-toast"; // NPM: React Hot Toast for toast messages.

/*
* COMPONENTS
*/
import ScrollToTop from './components/scrollToTop/index';
import './index.css'

/*
* AUTH
*/
import Context from "./context";
const SignUp = React.lazy(() => import("./views/auth/signUp/SignUpDefault.js"));
const SignIn = React.lazy(() => import("views/auth/signIn/SignInDefault.js"));
const ForgotPassword = React.lazy(() => import("views/auth/forgotPassword/index.js"));
const CreateTestPage = React.lazy(() => import('./views/admin/adminPanel/createTestPage/index'));
const TestIntroPage = React.lazy(() => import('./views/admin/website/testIntroPage/index'));
const ExamPage = React.lazy(() => import('./views/admin/website/examPage/index'));
const ResultPage = React.lazy(() => import('./views/admin/website/testResult/index'));
// WEBSITE Components
const LandingPage = React.lazy(() => import("./views/admin/website/LandingPage/index"));
const NotFound = React.lazy(() => import('./views/admin/website/notFound/index'));
const PricingPage = React.lazy(() => import('./views/admin/website/pricing/index'));
const ManageTestPage = React.lazy(() => import('./views/admin/adminPanel/manageTestpage/index'));
const TestsPage = React.lazy(() => import('./views/admin/website/tests/index'));
const ResultDetail = React.lazy(() => import('./views/admin/website/resultDetails/index'));
const AboutUs = React.lazy(() => import('./views/admin/website/aboutUs/index'));
const ContactUsPage = React.lazy(() => import('./views/admin/website/contactUs/index'));
const TermsAndConditions = React.lazy(() => import('./views/admin/website/termsAndConditions/index'));
const ProfilePage = React.lazy(() => import('./views/admin/website/profilePage/index'));
const OpenOnLaptop = React.lazy(() => import('./views/admin/website/openOnLaptop/index'));

/*
 * OBJECT
 */
const Index = ({ account }) => {

  console.log(account, account?.isUserLoggedIn && account.accountType === "ACCOUNT_ADMIN", "account")
  const [context, setContext] = React.useState({});
  const [isMobileOrTablet, setIsMobileOrTablet] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobileOrTablet(window.innerWidth <= 1024);
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const protectedRoutes = (
      <Switch>
        {/* IF USER IS NOT LOGGED IN */}
        {!account.isUserLoggedIn &&
            <Switch>
              <Route exact path="/signup" component={SignUp} />
              <Route exact path="/signin" component={SignIn} />
              <Route exact path="/forget-password" component={ForgotPassword} />

              {/* Catch-all Redirect for any other route */}
              <Redirect to="/signin" />
            </Switch>
        }
        {(account.isUserLoggedIn && account.accountType === "ACCOUNT_DEFAULT") &&
            <Switch>
              <Route exact path="/tests" component={TestsPage} />
              <Route exact path="/test-introduction/:testId" component={TestIntroPage} />
              <Route exact path="/test-solve/:testId" component={ExamPage} />
              <Route exact path="/test-result/:testId" component={ResultPage} />
              <Route exact path="/result-detail/:testId" component={ResultDetail} />
              <Route exact path="/profile" component={ProfilePage} />

              {/* Catch-all Redirect for any other route */}
              <Redirect from="/signin" to='/' />
              <Redirect from="/signup" to='/' />
              <Redirect to="/signin" />
            </Switch>
        }

        {(account?.isUserLoggedIn && account.accountType === "ACCOUNT_ADMIN") &&
            <Switch>
              <Route exact path="/admin-panel-page" component={CreateTestPage} />
              <Route exact path="/admin-page-manage-test/:testId" component={ManageTestPage} />

              {/* Catch-all Redirect for any other route */}
              <Redirect from="/signin" to='/' />
              <Redirect from="/signup" to='/' />
              <Redirect to="/signin" />
            </Switch>
        }

        {(account?.isUserLoggedIn && account.accountType === "ACCOUNT_DEFAULT") &&
            <Switch>
              <Route exact path="/" component={LandingPage} />

              {/* Catch-all Redirect for any other route */}
              <Redirect from="/signin" to='/' />
              <Redirect from="/signup" to='/' />
              <Redirect to="/signin" />
            </Switch>
        }
      </Switch>
  );

  return (
      <Context.Provider value={{ context, setContext }}>
        <Toaster />
        <Router>
          <ScrollToTop />
          <Suspense fallback={<Flex w='100vw' h='100vh'><Spinner color="#FFDEC7" mx='auto' my='auto' /></Flex>}>
            <Switch>
              {/* WEBSITE ROUTES */}
              <Route exact path="/" component={LandingPage} />
              <Route exact path="/pricing" component={PricingPage} />
              <Route exact path="/about-us" component={AboutUs} />
              <Route exact path="/contact-us" component={ContactUsPage} />
              <Route exact path="/terms-and-conditions" component={TermsAndConditions} />

              {isMobileOrTablet ? (
                  <Route component={OpenOnLaptop} />
              ) : (
                  protectedRoutes
              )}

              {/* IF NO ROUTE IS AVAILABLE SHOW 404 PAGE */}
              <Route component={NotFound} />
            </Switch>
          </Suspense>
        </Router>
      </Context.Provider>
  );
};

/*
* REDUX
*/
const _MapStateToProps = (__state) => ({ account: __state.Account });
const _MapDispatchToProps = (__dispatch) => ({
  AccountUpdate: (u) => __dispatch({ type: "ACCOUNT_UPDATE", Account: u }),
  AccountLogout: () => __dispatch({ type: "ACCOUNT_CLEAR" }),
});

/*
* EXPORT
*/
export default connect(_MapStateToProps, _MapDispatchToProps)(Index);
